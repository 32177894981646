import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';
import Cookies from "js-cookie";
import $ from "jquery";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './contact.scss';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactMessage: ''
        };
  
        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.msgChange = this.msgChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#contactForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }
  
        let post_data = formObject.serialize();
  
        console.log(post_data);
  
        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactMessage: ''
        });
  
        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                let r = data[0];

                $('#contactForm').hide();
                $('.form-wrapper .thankyou').show();
                formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
            },
            // Fail..
            error: function(result) {
              let r = result[0];
  
                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }
  
    nameChange(e){
        this.setState({contactName: e.target.value})
    }
  
    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }
  
    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }
  
    msgChange(e){
        this.setState({contactMessage: e.target.value})
    }

    render() {
        return(
            <>
                <div className="form-wrapper">
                    <form id="contactForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                        <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Name"}/>
                        <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                        <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                        <FormField fieldID={"message"}  valChange={this.msgChange} val={this.state.contactMessage} type={"multiline"}  name={"message"} label={"Message"}/>
                        <button type="submit" className="btn btn-black">Contact Us</button>
                        <div className="form-group">
                            <input type="hidden" name="referrer" value="window.location" />
                            <input type="hidden" name="auth" value="788aa7eb-8ceb-498d-b0b4-002c85f56fb4" />
                            <input type="text" className="d-none hu" id="human-check" name="human-check" />
                            <div className="feedback"></div>
                            <div className="fix"></div>
                        </div>
                    </form>
                    <div className="thankyou">
                        <h4>Thank you for your inquiry.</h4>
                        <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                    </div>
                </div>
            </>
        )
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className="metrix default-template">
            <h1 className="content-block">Contact Merit Mile</h1>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)